.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.loader {
    width: 100px;
    height: 70px;
    margin: 50px auto;
    position: relative;
}

.loader span {
    display: block;
    width: 5px;
    height: 10px;
    background: #e43632;
    position: absolute;
    bottom: 0;
    animation: loading-1 2.25s infinite ease-in-out;
}

.loader span:nth-child(2) {
    left: 11px;
    animation-delay: .2s;
}

.loader span:nth-child(3) {
    left: 22px;
    animation-delay: .4s;
}

.loader span:nth-child(4) {
    left: 33px;
    animation-delay: .6s;
}

.loader span:nth-child(5) {
    left: 44px;
    animation-delay: .8s;
}

.loader span:nth-child(6) {
    left: 55px;
    animation-delay: 1s;
}

.loader span:nth-child(7) {
    left: 66px;
    animation-delay: 1.2s;
}

.loader span:nth-child(8) {
    left: 77px;
    animation-delay: 1.4s;
}

.loader span:nth-child(9) {
    left: 88px;
    animation-delay: 1.6s;
}

@-webkit-keyframes loading-1 {
    0% {
        height: 10px;
        transform: translateY(0px);
        background: #ff4d80;
    }
    25% {
        height: 60px;
        transform: translateY(15px);
        background: #3423a6;
    }
    50% {
        height: 10px;
        transform: translateY(-10px);
        background: #e29013;
    }
    100% {
        height: 10px;
        transform: translateY(0px);
        background: #e50926;
    }
}

@keyframes loading-1 {
    0% {
        height: 10px;
        transform: translateY(0px);
        background: #ff4d80;
    }
    25% {
        height: 60px;
        transform: translateY(15px);
        background: #3423a6;
    }
    50% {
        height: 10px;
        transform: translateY(-10px);
        background: #e29013;
    }
    100% {
        height: 10px;
        transform: translateY(0px);
        background: #e50926;
    }
}

.toast-header-error {
    background: #ff9494 !important;
    color: black !important;
}

.toast-header-success {
    background: #a7d0ad !important;
    color: black !important;
}