.main-div {
    height: 100%;
    border: 1px solid #dee2e6;
    border-radius: 5px;
}

#main-tab-content {
    margin: 0;
}

.logout-button {
    margin-left: auto;
}

main > .container {
    padding: 60px 15px 0;
}

.footer {
    background-color: #f5f5f5;
}

.container-fluid {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.last-nav {
    margin-left: auto;
    margin-top: 1px;
    margin-right: 5px;
}

.cursored {
    cursor: pointer;
    margin-left: 10px;
    margin-right: -5px;
}

.cursor-pointer {
    cursor: pointer;
}

.table tbody tr.highlight td {
    background-color: #ddd;
}

.actions-p {
    margin-bottom: 0 !important;
}

.actions-button {
    height: 24px;
    min-height: 24px;
    width: 24px;
    padding: 0;
    margin-right: 10px;
}

.grid-buttons-div {
    display: flex;
}

.navbar-toggler-icon {
    background-image: url('https://mdbootstrap.com/img/svg/hamburger7.svg?color=282c34');
}

.dropleft button {
    padding: 0;
    min-height: 26px;
    box-shadow: none !important;
}

.nav-item a {
    outline: none;
}

.main-table {
    background-color: #f8f9fa !important;
    margin-bottom: 0 !important;
}

.main-container {
    padding: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.main-main {
    min-height: 100%;
    height: 100%;
}

.grid-th {
    border-top: none;
}

#myTab > li > button {
    box-shadow: none;
}

.tableFixHead {
    overflow-y: auto;
}

.tableFixHead thead th {
    position: sticky;
    top: 0;
}

/* Just common table stuff. Really. */
.tableFixHead table {
    border-collapse: collapse;
    width: 100%;
}

.tableFixHead th, td {
    padding: 8px 16px;
    border-top: none;
}

.tableFixHead th {
    background: #eee;
}

.fa-1x {
    font-size: 1.5rem;
    color: gray;
}

.tr-custom td {
    vertical-align: middle;
}

.title {
    margin-left: 15px
}

.row-border {
    border-right: 1px solid lightgray;
}