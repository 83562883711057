.login-form {
    width: 340px;
    margin: 50px auto;
}

.login-form form {
    margin-bottom: 15px;
    background: #f7f7f7;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
    padding: 30px;
}

.login-form h2 {
    margin: 0 0 15px;
}

.form-control, .btn {
    min-height: 38px;
}

#invalid-credentials-span {
    margin-bottom: 10px;
    text-align: center;
    display: block;
}